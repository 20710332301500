<template>
<div>
    <v-simple-table dense>
        <template v-slot:default>
            <thead>
                <tr>
                    <th width="25%" class="text-left">Estado</th>
                    <th class="text-right">Cantidad</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dato, i) in datos" :key="i">
                    <td><b>{{ dato.nombre }}</b></td>
                    <td class="text-right">
                        <template v-if="!dato.clasificacion">{{ dato.valor }}</template>
                        <template v-else>
                            <v-row dense no-gutters v-for="(clasif, i) in dato.clasificacion" v-bind:key="i">
                                <v-col cols="6" align="center">
                                    <span class="text--primary font-weight-bold">{{ clasif.nombre }}</span>
                                </v-col>
                                <v-col cols="6" align="right">
                                    <span class="text--primary">{{ clasif.valor }}</span>
                                </v-col>
                            </v-row>
                        </template>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</div>
</template>

<script>
import raw_estados from '@/assets/estados'

export default {
    props: ['datos'],
    data: () => ({
        estados: raw_estados['incidente'],
        textos:
        {
            tratamientos: "En Tratamiento",
            cerrados: "Cerrados",
            invalidados: "Invalidados",
            reasignados: "Reasignados",
            total_validados: "Total Incidentes Validados",
            total: "Total"
        }
    }),
}
</script>