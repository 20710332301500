<template>
    <v-dialog v-model="visible" max-width="800" persistent transition="fade-transition">
        <WindowCard title="Exportar Foro Semanal" actions-align="right">
            <v-label>
                Está a un paso de generar el documento de Foro Semanal, el cual resume los compromisos ambientales cerrados y los que están en tratamiento.<br/><br/>
            </v-label>
            <v-label>
                Se exportará un foro semanal que considera Incidentes desde el día:<br/><b>{{ fecha | moment('LL') }} hasta el {{ fecha_next | moment('LL') }}</b>.
            </v-label>
            <v-form id="form" ref="download_form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-label>Cambiar fecha</v-label>
                        <DatePickerText dense v-model="fecha" :rules="[rules.required]" />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Cancelar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="download_form" @click="post_download">
                    <v-icon>save_alt</v-icon>
                    <span class="ml-2 subtitle-2">Descargar</span>
                </v-btn>
            </template>
        </WindowCard>
        <DownloadDialog ref="down_dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import DownloadDialog from '@/shared/components/DownloadDialog'
import DatePickerText from '@/shared/components/DatePickerText'
import LoadingOverlay from '@/dash/components/LoadingOverlay'
import { axiosHelper, messages, valid_required } from '@/helpers'
import moment from 'moment'
import download from 'downloadjs'

export default {
    components: {
        DatePickerText,
        DownloadDialog,
        LoadingOverlay,
        WindowCard
    },
    data: () => ({
        visible: false,
        fecha: moment().format("YYYY-MM-DD"),
        rules: {
            required: value => valid_required(value)
        }
    }),
    computed: {
        fecha_next() {
            return moment(this.fecha).add(1, 'week').format("YYYY-MM-DD")
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        post_download() { 
            if(this.$refs.download_form) 
            {
                if(!this.$refs.download_form.validate()) 
                {
                    this.error();
                    return;
                }
            }

            this.$refs.down_dialog.show('reportes/incidentes_foro_semanal/');
        },
        error() {
            let text = messages.texts["error_check_fields"];
            this.$refs.dialog.show(messages.titles["error_generico"], text, "error");
        }
    }
}
</script>