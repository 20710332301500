<template>
    <v-data-table dense :headers="headers" :items="value" :search="busqueda" :custom-filter="filtro" :mobile-breakpoint="0" :items-per-page="-1" hide-default-footer>
        <template v-slot:item.cod_incidente="{ item }">
            <router-link :to="{ name: 'dashboard_incidentes_item', params: { id: item.id } }">{{ item.cod_incidente }}</router-link>
        </template>
        <template v-slot:item.fecha_incidente="{ item }">
            {{ item.fecha_incidente | moment("L") }}
        </template>
        <template v-slot:item.titulo="{ item }">
            <span :title="item.titulo">{{ item.titulo | see_more(35) }}</span>
        </template>
        <template v-slot:item.persona_autor="{ item }">
            <v-chip dark color="indigo" v-if="!!item.persona_autor">
                <v-icon left>account_circle</v-icon>{{ item.persona_autor }}
            </v-chip>
            <template v-else>No asignado</template>
        </template>
        <template v-slot:item.estado="{ item }">
            <v-chip dark :color="item.estado | color_estado('incidente')">
                {{ item.estado | estado("incidente") }}
            </v-chip>
        </template>
        <template v-slot:item.estado_tiempo="{ item }">
            <v-chip dark :color="item.estado_tiempo | color_estado('tiempo')">
                {{ item.estado_tiempo | estado("tiempo") }}
            </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
            <slot name="item-actions" v-bind:item="item"></slot>
        </template>
        <template v-slot:footer>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" :total-visible="10" @input="emit_change_page" />
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ['value', 'page-count', 'busqueda', 'filtro'],
    data: () => ({
        page: 1,
        headers: [
            { text: "ID", value: "id" },
            { text: "Código", value: "cod_incidente" },
            { text: "Fecha", value: "fecha_incidente" },
            { text: "Título", value: "titulo" },
            { text: "Clas.", value: "clasificacion" },
            { text: "Área", value: "area" },
            { text: "Gerencia", value: "gerencia" },
            { text: "Empresa", value: "empresa" },
            { text: "Responsable", value: "persona_autor" },
            { text: "Estado", value: "estado" },
            { text: "Tiempo", value: "estado_tiempo" },
            { text: "", value: "actions", sortable: false, width: '42px' } 
        ]
    }),
    methods: {
        emit_change_page(num) {
            if(!num) return;

            console.log(num);
            this.$emit('page-change', num);
        }
    }
}
</script>