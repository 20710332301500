<template>
    <div>
        <v-row>
            <v-col>
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_incidentes_create' }">
                    <v-icon class="mr-2">assignment</v-icon>
                    <span class="subtitle-2">Añadir Registro</span>
                </v-btn>
                <v-btn v-if="$parent.$parent.get_permission('export_1')" class="ma-1" large solo depressed color="secondary" @click="$refs.exportar_dialog.show()">
                    <v-icon class="mr-2">file_copy</v-icon>
                    <span class="subtitle-2">Exportar Tabla</span>
                </v-btn>
                <v-btn v-if="$parent.$parent.get_permission('export_2')" class="ma-1" large solo depressed color="secondary" @click="$refs.foro_dialog.show()">
                    <v-icon class="mr-2">file_copy</v-icon>
                    <span class="subtitle-2">Foro Semanal</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="6" v-if="$parent.$parent.get_permission('show_resumen')">
                <DashCard body-class="pa-0" title="Resúmen" title-icon="timeline">
                    <v-tabs fixed-tabs>
                        <v-tab class="subtitle-1 font-weight-bold">General</v-tab>
                        <v-tab class="subtitle-1 font-weight-bold" v-if="$parent.$parent.user.groups.some(r => ['administradores', 'administradores_r2'].includes(r))">Por gerencias</v-tab>
                        <v-tab-item>
                            <v-divider />
                                <v-row no-gutters class="ma-0" dense>
                                    <v-col cols="12" sm="6">
                                        <v-skeleton-loader type="list-item, list-item, list-item, list-item, list-item" :loading="loading">
                                            <TableResumenIncidentes :datos="totales" />
                                        </v-skeleton-loader>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-skeleton-loader class="pa-2" type="image" :loading="loading">
                                            <ChartResumenIncidentes :datos="grafico" height="320" />
                                        </v-skeleton-loader>
                                    </v-col>
                                </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <IncidentesValidados class="px-4" :height="320" />
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
            <v-col cols="12" lg="6" v-if="$parent.$parent.get_permission('show_resumen')">
                <DashCard title="Porcentajes generales" title-icon="timeline">
                    <v-skeleton-loader type="image" :loading="loading">
                        <v-row>
                            <v-col cols="12" sm="4" v-for="(item, index) in porcentajes" v-bind:key="index">
                                <ProgressCircle :title="item.nombre" v-model="item.valor" :color="item.vue_color" />
                            </v-col>
                        </v-row>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <DashCard :title="`Incidentes registrados (${total_count})`" title-icon="list" body-class="pa-0">
                    <BuscadorTabla ref="buscador" class="px-4 pt-4" v-model="busqueda" :target="tabla_items" :types="busqueda_settings" :estados="estados" />
                    <v-divider />
                    <v-skeleton-loader type="table" :loading="loading">
                        <IncidenteTable v-model="tabla_items" :page-count="total_pages" @page-change="go_page" :busqueda="busqueda" :filtro="filtro"> 
                            <template v-slot:item-actions="{ item }">
                                <v-menu offset-y v-if="$parent.$parent.get_permission('read') || $parent.$parent.get_permission('edit') || $parent.$parent.get_permission('delete')">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text fab small color="primary" depressed v-on="on">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                        <v-list-item v-if="$parent.$parent.get_permission('read')" :to="{ name: 'dashboard_incidentes_item', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>visibility</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Ver</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit')" :to="{ name: 'dashboard_incidentes_edit', params: { id: item.id } }">
                                            <v-list-item-icon>
                                                <v-icon>edit</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$parent.$parent.get_permission('edit') && $parent.$parent.get_permission('delete')" @click="ask_eliminar(item)">
                                            <v-list-item-icon>
                                                <v-icon>delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </IncidenteTable>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
        <DialogExportarTablaGeneral ref="exportar_dialog" type="incidentes" />
        <DialogExportarForo ref="foro_dialog" />
        <CommonDialog ref="dialog_delete" @onClose="delete_item" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import DialogExportarTablaGeneral from '@/dash/dialogs/DialogExportarTablaGeneral'
import DialogExportarForo from '@/dash/dialogs/DialogExportarForo'
import TableResumenIncidentes from '@/dash/components/TableResumenIncidentes'
import ChartResumenIncidentes from '@/dash/components/ChartResumenIncidentes'
import ProgressCircle from '@/dash/components/ProgressCircle'
import BuscadorTabla from '@/shared/components/BuscadorTabla'
import IncidentesValidados from '@/dash/components/IncidentesValidados'
import CommonDialog from '@/shared/components/CommonDialog'
import raw_estados from '@/assets/estados'
import IncidenteTable from '@/dash/components/IncidenteTable'

export default {
    components: {
        DashCard,
        TableResumenIncidentes,
        ChartResumenIncidentes,
        DialogExportarTablaGeneral,
        DialogExportarForo,
        ProgressCircle,
        BuscadorTabla,
        IncidentesValidados,
        IncidenteTable,
        CommonDialog
    },
    data: () => ({
        index: null,
        busqueda: "",
        estados: [
            {
                columna: "estado", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los estados' }, ...raw_estados['incidente']] 
            },
            { 
                columna: "estado_tiempo", 
                icon: "av_timer", 
                actual: null, 
                lista: [{ value: null, text: 'Todos los tiempos' }, ...raw_estados['tiempo']] 
            }
        ],
        busqueda_settings: [
            { value: null, text: "Todo" },
            { value: "id", text: "ID" },
            { value: "cod_incidente", text: "Código Incidente" },
            { value: "fecha_incidente", text: "Fecha" },
            { value: "titulo", text: "Título" },
            { value: "clasificacion", text: "Clasificacion" },
            { value: "area", text: "Área" },
            { value: "empresa", text: "Empresa" },
            { value: "gerencia", text: "Gerencia" },
            { value: "persona_autor", text: "Responsable" }
        ]
    }),
    computed: {
        resumen() {
            if(!this.index) return {};
            return this.index.resumen;
        },
        items() {
            if(!this.index) return [];
            return this.index.results;
        },
        total_count() {
            if(!this.index) return 0;
            return this.index.count;
        },
        total_pages() {
            if(!this.index) return 1;
            return this.index.pages;
        },
        tabla_items() {
            if(!this.$refs.buscador) return this.items;
            return this.items.filter(x => this.$refs.buscador.filter_estado(x));
        },
        totales() {
            if(!this.resumen) return {};
            return this.resumen.totales || {};
        },
        porcentajes() {
            if(!this.resumen) return {};
            return this.resumen.porcentajes || [];
        },
        grafico() {
            if(!this.resumen) return {};
            return this.resumen.grafico || {};
        }
    },
    methods: {
        go_page(num) {
            this.loading = true;
            axiosHelper.get('incidentes/?page=' + num)
                .then(response => {
                    this.index = response.data;
                    this.loading = false;
                });
        },
        filtro(value, search, item) {
            return this.$refs.buscador.filtro(value, search, item);
        },
        ask_eliminar(item) {
            this.$refs.dialog_delete.showDialog(messages.titles['question_delete_item'], messages.texts['delete_item'], "yes_no", item);
        },
        delete_item(response, item) {
            if(response == 'yes') {
                axiosHelper.del('incidentes/' + item.id + '/')
                    .then(response => {
                        this.$parent.$parent.reloadComponent();
                    });
            }
        }
    },
    created() {
        this.loading = true;
        this.$emit('title', { text: "Compromisos Ambientales", icon: "inbox" });
    
        axiosHelper.get('incidentes/')
            .then(response => {
                this.index = response.data;
                this.loading = false;
            });
    }
}
</script>