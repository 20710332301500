<template>
    <v-row>
        <v-col cols="12">
            <v-label>Gerencia</v-label>
            <v-select 
                v-model="gerencia"
                :items="gerencias"
                hide-details
                solo dense flat outlined
                label="Gerencias" 
                prepend-inner-icon="account_box"
                item-text="name"
                return-object />
        </v-col>
        <v-col cols="12">
            <vue-apex-charts type="bar" :height="height" :series="series" :options="options" />
        </v-col>
    </v-row>
</template>

<script>
import { axiosHelper } from '@/helpers'
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts
    },
    props: {
        height: {
            type: Number,
            default: 180
        }
    },
    data: () => ({
        gerencia: null,
        gerencias: [],
        options: 
        {
            chart: { 
                width: '100%',
                height: '100%',
                offsetX: 0,
                offsetY: 0,
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: ['P1', 'P2', 'P3']
            }
        }
    }),
    computed: {
        series() {
            return this.gerencia ? [this.gerencia] : []
        }
    },
    mounted() {
        axiosHelper.get('incidentes/resumen_validados/')
            .then(response => this.gerencias = response.data)
    }
}
</script>